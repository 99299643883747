// Shared Funtions

export function createMicrophoneAudioTrack(currentMinId) {
    return new Promise(resolve => {
        AgoraRTC.createMicrophoneAudioTrack({ microphoneId: currentMinId }).then(audioTrack => {
            resolve(audioTrack);
        });
    });
}

export function createCameraVideoTrack(currentCamId) {
    return new Promise(resolve => {
        AgoraRTC.createCameraVideoTrack({ cameraId: currentCamId }).then(videoTrack => {
            resolve(videoTrack);
        });
    });
}


export function createShareTrack(options) {
    return new Promise((resolve, reject) => {
        AgoraRTC.createScreenVideoTrack(options, "disable").then((shareVideoTrack) => {
            resolve(shareVideoTrack);
        })
    });
}