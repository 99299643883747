import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { APP_LANGUAGES, DEFAULT_LANGUAGE } from '@shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  constructor(private translate: TranslateService) {
    translate.addLangs(APP_LANGUAGES.map(l => l.key));
    translate.setDefaultLang(DEFAULT_LANGUAGE);
    translate.use(DEFAULT_LANGUAGE);
  }

  setLocale(value: string) {
    this.translate.use(value);
  }
}
