import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { UserPreferences } from '@core/interfaces/user-preferences';
import { DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS } from '@shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private readonly storageKey = LOCAL_STORAGE_KEYS.USER_PREFERENCES;

  private preferences: UserPreferences = this.loadPreferences();

  private preferencesSubject = new BehaviorSubject<UserPreferences>(this.preferences);
  preferences$ = this.preferencesSubject.asObservable();

  constructor() {}

  private loadPreferences(): UserPreferences {
    const storedPreferences = localStorage.getItem(this.storageKey);
    if (storedPreferences) {
      return JSON.parse(storedPreferences);
    }
    // Default preferences
    return this.defaultPreferences();
  }

  defaultPreferences() {
    return {
      deviceSet: false,
      selectedCameraId: null,
      selectedMicrophoneId: null,
      isCameraEnabled: true,
      isMicrophoneEnabled: true,
      isDarkModeEnabled: false,
      preferedLanguage: DEFAULT_LANGUAGE
    };
  }

  private savePreferences(): void {
    localStorage.setItem(this.storageKey, JSON.stringify(this.preferences));
  }

  setPreferences(newPreferences: Partial<UserPreferences>) {
    this.preferences = { ...this.preferences, ...newPreferences };
    this.preferencesSubject.next(this.preferences);
    this.savePreferences();
  }

  getPreferences(): UserPreferences {
    return this.preferences;
  }
}
