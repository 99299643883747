import { Constants } from "./constants";
import { createMicrophoneAudioTrack, createCameraVideoTrack, createShareTrack } from "./shared.js";


export let agoraLibraryPath;

let client;
let shareClient;

let logLevel = 4;
let agoraClientUID;

// LIST
var mics = [];
var playbacks = [];
var cams = [];
var remoteUsers = {};

var localTracks = { videoTrack: null, audioTrack: null }
var currentMic = undefined;
var currentCam = undefined;
var localSCreenTrack = null;

//SETTINGS
var localTracksSettings = { videoTrack: null, audioTrack: null }
var currentMicSettings = undefined;
var currentCamSettings = undefined;
let volumeAnimation;
var percentage = 0;

var camerasAvailable = false;
var microphonesAvailable = false;

var experienceReady = false;

var selectedMicIDLocal = undefined;
var selectedCamIDLocal = undefined;

var isLocalTrackspublished = false;

export const EVENT = {
    USER_PUBLISHED: 'user-published',
    USER_UNPUBLISHED: 'user-unpublished',
    USER_VOLUMEINDICATOR: 'volume-indicator',
    USER_SHARE_PUBLISHED: 'user-share-published',
    USER_SHARE_UNPUBLISHED: 'user-share-unpublished',
    USER_JOINED_CHANNEL: 'user-joined-channel',
    USER_LEAVE_CHANNEL: 'user-leave-channel',
    VOLUME_WAVE_SETTINGS: 'volume-wave',
    STOP_SCREEN_SHARE: 'stop-screen',

}

let events = {};

export var on = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var off = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

let emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function init() {
    console.log("load");

    return new Promise(resolve => {

        agoraLibraryPath = 'https://raw.githubusercontent.com/linkroom/linkroomv3_assets/main/assets/libs/AgoraRTC_N-4.20.2.js';
        localTracks.audioTrack = undefined;
        localTracks.videoTrack = undefined;

        try {
            if (AgoraRTC != undefined) {
                resolve();
            }
        } catch (error) {
            importingLibrary().then(async (AgoraRTC) => {
                AgoraRTC.setLogLevel(logLevel);
                getDevicesAvailable().then(() => {
                    resolve();
                });
            });
        }


    });
}

export function createClient() {
    console.log("create AGORA client");

    client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    shareClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    client.enableAudioVolumeIndicator();

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("volume-indicator", handleUserVolumeIndicator);

    shareClient.on("user-published", handleUserSharePublished);
    shareClient.on("user-unpublished", handleUserShareUnpublished);
}

function getDevicesAvailable() {

    return new Promise((resolve, reject) => {

        AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
            var message = changedDevice.state == "ACTIVE" ? "Microphone found!" : "Microphone removed!";
            console.log(message);
            getMicrophonesAvailable();
        };

        AgoraRTC.onCameraChanged = async (changedDevice) => {
            var message = changedDevice.state == "ACTIVE" ? "Camera found!" : "Camera removed!";
            console.log(message);
            getCamerasAvailable();
        };

        Promise.all([getCamerasAvailable(), getMicrophonesAvailable()]).then((response) => {
            resolve();
        });
    });
}

function getCamerasAvailable() {
    return new Promise((resolve, reject) => {
        AgoraRTC.getCameras().then((_cams) => {
            cams = _cams
            camerasAvailable = cams.length > 0 ? true : false;
            emit("onCamerasAvailable", { available: camerasAvailable, cams });
            resolve();
        });
    });

}

function getMicrophonesAvailable() {
    return new Promise((resolve, reject) => {
        AgoraRTC.getMicrophones().then((_mics) => {
            mics = _mics
            microphonesAvailable = mics.length > 0 ? true : false;
            emit("onMicrophonesAvailable", { available: microphonesAvailable, mics });
            resolve();
        });
    });

}

function handleUserPublished(user, mediaType) {

    return new Promise((resolve, reject) => {
        remoteUsers[user.uid] = user;
        client.subscribe(user, mediaType).then((remoteTrack) => {
            emit(EVENT.USER_PUBLISHED, { user, mediaType });
            console.log(remoteTrack);
            resolve(remoteTrack);
        }).catch((error) => {
            reject(error);
        });
    });

}

function handleUserUnpublished(user, mediaType) {
    try {
        return new Promise((resolve, reject) => {
            if (mediaType === 'video')
                delete remoteUsers[user.uid];

            emit(EVENT.USER_UNPUBLISHED, { user, mediaType });
            resolve();
        });
    } catch (error) {
        reject(error);
    }
}

function handleUserVolumeIndicator(volumes) {
    emit(EVENT.USER_VOLUMEINDICATOR, { volumes });
}

function handleUserSharePublished(user, mediaType) {
    return new Promise((resolve, reject) => {
        shareClient.subscribe(user, mediaType).then((remoteTrack) => {
            emit(EVENT.USER_SHARE_PUBLISHED, { user, mediaType });
            resolve(remoteTrack);
        }).catch((error) => {
            reject(error);
        });

    });
}

function handleUserShareUnpublished(user, mediaType) {
    emit(EVENT.USER_SHARE_UNPUBLISHED, { user, mediaType });
}

export function joinChannel(appId, channelName, token, id) {

    console.table(appId, channelName, token, id);

    return new Promise((resolve, reject) => {

        client.join(appId, channelName, token || null, id || null).then((_uid) => {
            resolve({ agoraClientUID: _uid });
        }, error => {
            console.log(error);
            reject(error);
        }).catch(error => {
            reject(error);
        });

    });

};

export function joinShareScreenChanel(appId, channelName, token, id) {
    return new Promise((resolve, reject) => {
        shareClient.join(appId, channelName, token || null, id || null).then((_shareUid) => {
            resolve({ agoraShareUId: _shareUid });
        }, error => {
            console.log(error);
            reject(error);
        }).catch(error => {
            reject(error);
        });

    });
}

export function leaveChannel() {

    // if (localTracks.audioTrack) {
    //     localTracks.audioTrack.stop();
    //     localTracks.audioTrack.close();
    //     localTracks.audioTrack = null;
    // }

    // if (localTracks.videoTrack) {
    //     localTracks.videoTrack.stop();
    //     localTracks.videoTrack.close();
    //     localTracks.videoTrack = null;
    // }
    client.leave();
}

export function leaveShareScreenChannel() {
    shareClient.leave();
}


export function createAndPublishLocalTracks(selectedCamID, selectedMicID, videoEnabled, audioEnabled) {

    return new Promise(async (resolve, reject) => {
        selectedMicIDLocal = selectedMicID;
        selectedCamIDLocal = selectedCamID;
        var [cam, mic] = await Promise.allSettled([createAndPublishVideoTrack(selectedCamID, videoEnabled), createAndPublishAudioTrack(selectedMicID, audioEnabled)])
        resolve({ cam, mic });
    });
}

function createAndPublishAudioTrack(currentMicId, micEnabled) {
    return new Promise((resolve, reject) => {

        if (mics.length > 0) {

            var deviceFound = mics.find(cam => cam.deviceId == currentMicId);
            currentMicId = deviceFound ? deviceFound.deviceId : null;

            if (micEnabled) {
                createMicrophoneAudioTrack(currentMicId).then((audioTrack) => {
                    localTracks.audioTrack = audioTrack;
                    var v = experienceReady ? 100 : 0;
                    localTracks.audioTrack.setVolume(v);
                    if (isLocalTrackspublished) {
                        publishLocalTrack(localTracks.audioTrack).then(() => {
                            resolve(localTracks.audioTrack);
                        })
                    } else {
                        resolve(localTracks.audioTrack);
                    }
                }).catch((err) => {
                    reject(Constants.NO_MICROPHONE_AVAILABLE);
                });
            } else {
                resolve(null);
            }

        } else {
            reject(Constants.NO_MICROPHONE_AVAILABLE);
        }

    });
}

export function publishLocalTracks(_isLocalTrackspublished) {
    isLocalTrackspublished = _isLocalTrackspublished;
    if (localTracks.audioTrack) {
        publishLocalTrack(localTracks.audioTrack).then(() => {
            // resolve(localTracks.audioTrack);
        })
    }
    if (localTracks.videoTrack) {
        publishLocalTrack(localTracks.videoTrack).then(() => {
            // resolve(localTracks.videoTrack);
        })
    }
}


export function upVolume() {
    experienceReady = true;
    if (localTracks.audioTrack) {
        localTracks.audioTrack.setVolume(100);
    }
}

function createAndPublishVideoTrack(currentCamId, camEabled) {
    return new Promise((resolve, reject) => {

        if (cams.length > 0) {

            var deviceFound = cams.find(cam => cam.deviceId == currentCamId);
            currentCamId = deviceFound ? deviceFound.deviceId : null;

            if (camEabled) {
                createCameraVideoTrack(currentCamId).then((videoTrack) => {
                    localTracks.videoTrack = videoTrack;
                    if (isLocalTrackspublished) {
                        publishLocalTrack(localTracks.videoTrack).then(() => {
                            resolve(localTracks.videoTrack);
                        })
                    } else {
                        resolve(localTracks.videoTrack);
                    }
                }).catch((err) => {
                    reject(Constants.NO_CAMERA_AVAILABLE);
                });
            } else {
                resolve(null);
            }

        } else {
            reject(Constants.NO_CAMERA_AVAILABLE);
        }
    });
}

export async function enableAudio(enable) {
    return new Promise(async (resolve, reject) => {

        if (microphonesAvailable == true) {
            if (localTracks.audioTrack) {
                await localTracks.audioTrack.setEnabled(enable);
                resolve(localTracks.audioTrack.enabled);
            } else {
                createAndPublishAudioTrack(selectedMicIDLocal, enable).then(() => {
                    resolve(localTracks.audioTrack.enabled);
                })
            }
        } else {
            console.log("No microphones available");
            reject("No microphones available");
        }
    });
}

export async function enableVideo(enable) {
    return new Promise((resolve, reject) => {

        if (camerasAvailable == true) {
            if (localTracks.videoTrack) {
                try {
                    localTracks.videoTrack.setEnabled(enable).then(() => {
                        var _enabled = localTracks.videoTrack.enabled;
                        resolve({ success: true, enabled: _enabled });
                    }).catch((error) => {
                        reject({ success: false, enabled: false });
                    });
                } catch (error) {
                    reject({ success: false, enabled: false });
                }
            } else {
                console.log(Constants.VIDEO_TRACK_NOT_FOUND);
                // reject({ success: false, enabled: false });
                createAndPublishVideoTrack(selectedCamIDLocal, enable).then(() => {
                    resolve({ success: true, enabled: enable });
                });
            }
        } else {
            console.log("No cameras available");
            reject("No cameras available");
        }
    });
}

export function switchCamera(deviceId) {
    return new Promise((resolve, reject) => {
        if (localTracks.videoTrack) {
            currentCam = cams.find(cam => cam.deviceId === deviceId);
            localTracks.videoTrack.setDevice(currentCam.deviceId).then(() => {
                resolve();
            }).catch((err) => {
                reject();
            });
        } else
            reject(Constants.VIDEO_TRACK_NOT_FOUND);
    });
}

export function switchMicrophone(deviceId) {
    return new Promise(async (resolve, reject) => {
        if (localTracks.audioTrack) {
            currentMic = mics.find(mic => mic.deviceId === deviceId);
            await localTracks.audioTrack.setDevice(currentMic.deviceId).then(() => {
                resolve();
            });
        } else
            reject(Constants.AUDIO_TRACK_NOT_FOUND);
    });
}

export function playVideoCamera(videoElementID) {
    if (localTracks.videoTrack) localTracks.videoTrack.play(videoElementID, { mirror: true });
}


function publishLocalTrack(_localtrack) {
    return new Promise(resolve => {
        client.publish([_localtrack]).then(() => {
            resolve();
        });
    })
}


export function setVideoCameraEncoder(config = '120p_1') {
    if (localTracks.videoTrack)
        localTracks.videoTrack.setEncoderConfiguration(config);
}


export function requestPermissions() {
    return new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(resolve).catch((e) => {
            reject('COMMUNICATION: request permissions failed');
        });
    });
}

export function createAndPublishShareTrack() {
    return new Promise(resolve => {
        createShareTrack({ encoderConfig: "720p_2" }).then((track) => {
            localSCreenTrack = track;
            console.log('publish share Track');
            localSCreenTrack.on("track-ended", () => {
                emit(EVENT.STOP_SCREEN_SHARE, {});
            });

            shareClient.publish(localSCreenTrack).then(() => {
                resolve();
            })
        });

    })
}

export function playScreenShare(videoScreenElementID) {
    if (localSCreenTrack) localSCreenTrack.play(videoScreenElementID, { mirror: false });
}



export function unpublishShareTrack() {
    return new Promise(resolve => {
        console.log('unpublish share Track');
        shareClient.unpublish(localSCreenTrack).then(() => {
            if (localSCreenTrack) {
                localSCreenTrack.stop();
                localSCreenTrack.close();
                localSCreenTrack = null;
            }
            resolve();
        })
    })
}


export function getCurrentFrameData() {
    return new Promise((resolve, reject) => {
        var data = null;
        if (localTracks.videoTrack != null) {
            const imageData = localTracks.videoTrack.getCurrentFrameData();
            data = {
                imageData: imageData.data.buffer,
                width: imageData.width,
                height: imageData.height,
                format: 'Uint8ClampedArray'
            };
        }

        resolve(data);
    });
}

export function getCurrentFrameDataSettings() {
    return new Promise((resolve, reject) => {
        var data = null;
        if (localTracksSettings.videoTrack != null) {
            const imageData = localTracksSettings.videoTrack.getCurrentFrameData();
            data = {
                imageData: imageData.data.buffer,
                width: imageData.width,
                height: imageData.height,
                format: 'Uint8ClampedArray'
            };
        }

        resolve(data);
    });
}



//SETTINGS
function createVideoTrackSettings(currentCamId, camEabled) {
    return new Promise((resolve, reject) => {

        if (cams.length > 0) {

            var deviceFound = cams.find(cam => cam.deviceId == currentCamId);
            currentCamId = deviceFound ? deviceFound.deviceId : null;

            createCameraVideoTrack(currentCamId).then((videoTrack) => {
                localTracksSettings.videoTrack = videoTrack;
                if (camEabled == false) {
                    enableVideoSettings(false);
                }
                resolve(cams);
            }).catch((err) => {
                reject(Constants.NO_CAMERA_AVAILABLE);
            });

        } else {
            reject(Constants.NO_CAMERA_AVAILABLE);
        }
    });
}

function getPlaybackDevicesSettings() {
    return new Promise((resolve, reject) => {
        AgoraRTC.getPlaybackDevices().then((_playbackDevices) => {
            playbacks = _playbackDevices;
            resolve(playbacks);
        });
    });
}


function createAudioTrackSettings(currentMicId, micEnabled) {
    return new Promise((resolve, reject) => {

        if (mics.length > 0) {

            var deviceFound = mics.find(mic => mic.deviceId == currentMicId);
            currentMicId = deviceFound ? deviceFound.deviceId : null;

            createMicrophoneAudioTrack(currentMicId).then((audioTrack) => {
                localTracksSettings.audioTrack = audioTrack;
                if (micEnabled) {
                    enableAudioSettings(false);
                }
                resolve(mics);
            }).catch((err) => {
                reject(Constants.NO_MICROPHONE_AVAILABLE);
            });
        } else {
            reject(Constants.NO_MICROPHONE_AVAILABLE);
        }
    });
}

export function switchCameraSettings(deviceId) {
    return new Promise((resolve, reject) => {
        if (localTracksSettings.videoTrack) {
            currentCamSettings = cams.find(cam => cam.deviceId === deviceId);
            localTracksSettings.videoTrack.setDevice(currentCamSettings.deviceId).then(() => {
                resolve();
            }).catch((err) => {
                reject();
            });
        } else
            reject(Constants.VIDEO_TRACK_NOT_FOUND);
    });
}

export function switchMicrophoneSettings(deviceId) {
    return new Promise(async (resolve, reject) => {
        if (localTracksSettings.audioTrack) {
            currentMicSettings = mics.find(mic => mic.deviceId === deviceId);
            await localTracksSettings.audioTrack.setDevice(currentMicSettings.deviceId).then(() => {
                resolve();
            });
        } else
            reject(Constants.AUDIO_TRACK_NOT_FOUND);
    });
}

export async function enableAudioSettings(enable) {
    return new Promise(async (resolve, reject) => {
        if (localTracksSettings.audioTrack) {
            await localTracksSettings.audioTrack.setEnabled(enable);
            resolve(localTracksSettings.audioTrack.enabled);
        } else
            reject(Constants.AUDIO_TRACK_NOT_FOUND);
    });
}


export async function enableVideoSettings(enable) {
    return new Promise((resolve, reject) => {
        if (localTracksSettings.videoTrack) {
            try {
                localTracksSettings.videoTrack.setEnabled(enable).then(() => {
                    var _enabled = localTracksSettings.videoTrack.enabled;
                    resolve({ success: true, enabled: _enabled });
                }).catch((error) => {
                    reject({ success: false, enabled: false });
                });
            } catch (error) {
                reject({ success: false, enabled: false });
            }
        } else {
            console.log(Constants.VIDEO_TRACK_NOT_FOUND);
            reject({ success: false, enabled: false });
        }
    });
}



export function playVideoTrackSettings(videoElement) {
    localTracksSettings.videoTrack.play(videoElement, { mirror: true });
}

export function closeLocalTracksSettings() {

    if (localTracksSettings.audioTrack) {
        localTracksSettings.audioTrack.stop();
        localTracksSettings.audioTrack.close();
        localTracksSettings.audioTrack = null;
    }

    if (localTracksSettings.videoTrack) {
        localTracksSettings.videoTrack.stop();
        localTracksSettings.videoTrack.close();
        localTracksSettings.videoTrack = null;
    }

}

export function setVolumeWaveSettings(enable) {
    if (enable)
        volumeAnimation = requestAnimationFrame(volumeWaveSettings);
    else
        cancelAnimationFrame(volumeAnimation);
}

function volumeWaveSettings() {
    volumeAnimation = requestAnimationFrame(volumeWaveSettings);
    if (localTracksSettings.audioTrack != null) {
        percentage = localTracksSettings.audioTrack.getVolumeLevel() * 100;
    }
    emit(EVENT.VOLUME_WAVE_SETTINGS, percentage);
}

export function settings(currentMicId, currentCamId, micEnabled, camEnabled) {
    return new Promise(async (resolve, reject) => {
        var [cams, mics, playbacks] = await Promise.allSettled([createVideoTrackSettings(currentCamId, camEnabled), createAudioTrackSettings(currentMicId, micEnabled), getPlaybackDevicesSettings()]);
        resolve({ cams, mics, playbacks });
    });
}



function importingLibrary() {
    return new Promise((resolve, reject) => {
        fetch(agoraLibraryPath)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(data => {
                const script = document.createElement('script');
                script.textContent = data;
                document.head.appendChild(script);
                resolve(AgoraRTC);
            })
            .catch(error => {
                console.error('Error al cargar el script:', error);
            });
    });

}