import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { OrganizationService } from '@features/services/organization.service';
import { APP_NAME } from '@shared/utils/constants';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private prefix = APP_NAME;

  constructor(
    private organizationService: OrganizationService,
    private title: Title
  ) {
    this.organizationService.currentOrganization$.subscribe(currentOrganization => {
      if (currentOrganization) {
        this.prefix = currentOrganization.name;
      }
    });
  }

  setTitle(title: string | undefined) {
    this.title.setTitle(`${this.prefix}${title ? ` - ${title}` : ''}`);
  }
}
