export let Constants = {
    DEVICE_CONNECTED: 'DEVICE_CONNECTED',
    DEVICE_IN_USE_DISCONNECTED: 'DEVICE_IN_USE_DISCONNECTED',
    DEVICE_DISCONNECTED: 'DEVICE_DISCONNECTED',
    DEVICE_DISCONNECTED_BUT_NOT_VIDEO_TRACK: 'DEVICE_DISCONNECTED_BUT_NOT_VIDEO_TRACK',
    DEVICE_CONNECTED_BUT_NOT_VIDEO_TRACK: 'DEVICE_CONNECTED_BUT_NOT_VIDEO_TRACK',

    VIDEO_TRACK_NOT_FOUND: "VIDEO_TRACK_NOT_FOUND",
    AUDIO_TRACK_NOT_FOUND: "AUDIO_TRACK_NOT_FOUND",

    NO_CAMERA_AVAILABLE: "NO_CAMERA_AVAILABLE",
    NO_MICROPHONE_AVAILABLE: "NO_MICROPHONE_AVAILABLE"
}